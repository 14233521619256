import React from 'react';

const program=[
    require("../assets/1.png"), // require("../assets/2.png"),require( "../assets/3.png"), require("../assets/4.png"), require("../assets/5.png"), require("../assets/6.png"), require("../assets/7.png"), require("../assets/8.png"),require ("../assets/9.png"),require ("../assets/10.png"),require ("../assets/11.png")
]
const Programs = () => {
    return (
      <div className="program-main">
        <div className="simple-flex">
          <div className="identifier"></div>
          <h4 className="bolder">KAYREX PROGRAMS</h4>
        </div>
        <div className='simple-flex'>
            {program.map(x=><img className='prog-img' alt="prog" src={x}/>)}
        </div>
      </div>
    );
}

export default Programs;
